import React from 'react'
import { pairing_image } from '../../assets/images/all_images'
import { GEOFAMILY_NAVER_BLOG_URL, GEOFAMILY_BLOGGER_BLOG_URL, GEOFAMILY_TUMBLR_BLOG_URL } from '../../extra/constants'
import { useTranslation } from "react-i18next";
import { languages } from '../../components/navbar/Navbar';
import cookies from 'js-cookie'  // JS-Cookie 패키지

import './usage.css'

const Usage = () => {
    const { t } = useTranslation()

    // 현재 선택된 언어를 cookie를 통해 알아야 한다.
    // 맨 처음에 Cookie가 비어 있을 때를 대비해서, localStorage에 설정된 언어를 사용하고, 없으면 최종으로 en 으로 설정됨.
    const cookieLanguageIsoCode = cookies.get('i18next') || localStorage.getItem('lng') || 'en'
    const currentLanguage = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)
    const getBlogLink = () => {
        if (currentLanguage.iso_code === 'ko') return GEOFAMILY_NAVER_BLOG_URL
        else if (currentLanguage.iso_code === 'en') return GEOFAMILY_BLOGGER_BLOG_URL
        else if (currentLanguage.iso_code === 'es') return GEOFAMILY_TUMBLR_BLOG_URL
        else return GEOFAMILY_BLOGGER_BLOG_URL
    }

    const usageStepsTexts = [
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_1"),
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_2"),
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_3"),
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_4"),
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_5"),
        t("TOKEN how_to_perform_pairing_between_the_controller_user_and_the_tracker_user_description_6"),
    ]
    return (
        <div className="gf__usage section__padding" id="usage">
            <div className="gf__usage-image">
                <img src={pairing_image} alt={t("TOKEN Usage Steps")} />
            </div>
            <div className="gf__usage-content">
                <h1 className="gradient__text">{t("TOKEN Usage Steps")}</h1>
                <h4>{t("TOKEN How to perform pairing between the controller user and the tracker user")}</h4>
                {   // Multiple text lines.
                    usageStepsTexts.map((item) => (
                        <p>{item}</p>
                    ))
                }
                <h4>{t("TOKEN For more information, visit our blog.")}</h4>

                <div className='gf__usage-blog'>
                    <a href={getBlogLink()} target="_blank" rel="noreferrer">
                        <button type="button">{t("TOKEN Blog")}</button>
                    </a>
                </div>
            </div>

        </div>
    )
}

export default Usage