import React from 'react'
import FeatureArray from '../../components/feature/FeatureArray';
import { kids_care_photo, senior_care_photo } from '../../assets/images/all_images'
import { useTranslation } from "react-i18next";

import './whatGF.css'

const WhatGF = () => {
    const { t } = useTranslation()

    const mainFunctionsArray = {
        title: t("TOKEN Main Functions"),
        texts: [
            t("TOKEN The location and status of tracker users is checked in real time."),
            t("TOKEN Locations where a mobile data network is not available are also tracked without omission."),
            t("TOKEN Pickup request to the precise location, and the transmission of moving route."),
            t("TOKEN Transmission of arrival and departure for virtual zones."),
            t("TOKEN Street View and Directions support."),
            t("TOKEN Transmission of rescue information such as moving route by a single SOS request in emergency and dangerous situations."),
            t("TOKEN Privacy protection that allows tracker user to transfer his/her own location at any time when necessary, but restricts his/her counterpart to check the location only during the permitted time."),
            t("TOKEN Optimal battery power consumption when collecting location information.")
        ]
    }
    const areasOfUseArray = {
        title: t("TOKEN Areas Of Use"),
        texts: [
            t("TOKEN ✔ Family safety on the way home (Child, Student, Woman, etc.)"),
            t("TOKEN ✔ Safety of visiting staff"),
            t("TOKEN ✔ Family care for the elderly"),
            t("TOKEN ✔ Family care for eementia patient"),
            t("TOKEN ✔ Emergency help of people with heart disease"),
            t("TOKEN ✔ Safety management of fieldworkers"),
            t("TOKEN ✔ Maritime safety"),
            t("TOKEN ✔ Safety of alone worker"),
            t("TOKEN ✔ Preparedness for horse-riding, skiing, or mountaineering accident"),
        ]
    }

    return (
        <div className="gf__whatgf section__margin--card" id="whatgf">
            <div className="gf__whatgf-heading">
                <h1 className="gradient__text">{t("TOKEN geofamily_app_definition")}</h1>
            </div>
            <div className="gf__whatgf-container-image">
                <img src={kids_care_photo} alt='Kids Care' />
                <img src={senior_care_photo} alt='Senior Care' />
            </div>

            <div className="gf__whatgf-container">
                <FeatureArray title={mainFunctionsArray.title} textArray={mainFunctionsArray.texts} />
                <FeatureArray title={areasOfUseArray.title} textArray={areasOfUseArray.texts} />
            </div>

        </div>
    )
}

export default WhatGF