import React from 'react'
import { Screen } from '../../components'
import { kor_c_1_main_map_1, kor_c_2_main_map_2, kor_c_3_navi_menu, kor_c_4_tracker_list, kor_c_5_noti } from './imports'
import { eng_c_1_main_map_1, eng_c_2_main_map_2, eng_c_3_navi_menu, eng_c_4_tracker_list, eng_c_5_noti } from './imports'
import { spa_c_1_main_map_1, spa_c_2_main_map_2, spa_c_3_navi_menu, spa_c_4_tracker_list, spa_c_5_noti } from './imports'
import { kor_t_1_main, kor_t_2_pickup_map, kor_t_3_sos } from './imports'
import { eng_t_1_main, eng_t_2_pickup_map, eng_t_3_sos } from './imports'
import { spa_t_1_main, spa_t_2_pickup_map, spa_t_3_sos } from './imports'
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'  // JS-Cookie 패키지
import { languages } from '../../components/navbar/Navbar';

import './screenshots.css'

const Screenshots = () => {
    const { t } = useTranslation()

    const controllerPhoneImagesKOR = [
        kor_c_1_main_map_1,
        kor_c_2_main_map_2,
        kor_c_3_navi_menu,
        kor_c_4_tracker_list,
        kor_c_5_noti,
    ]
    const controllerPhoneImagesENG = [
        eng_c_1_main_map_1,
        eng_c_2_main_map_2,
        eng_c_3_navi_menu,
        eng_c_4_tracker_list,
        eng_c_5_noti,
    ]
    const controllerPhoneImagesSPA = [
        spa_c_1_main_map_1,
        spa_c_2_main_map_2,
        spa_c_3_navi_menu,
        spa_c_4_tracker_list,
        spa_c_5_noti,
    ]
    const trackerPhoneImagesKOR = [
        kor_t_1_main,
        kor_t_2_pickup_map,
        kor_t_3_sos,
    ]
    const trackerPhoneImagesENG = [
        eng_t_1_main,
        eng_t_2_pickup_map,
        eng_t_3_sos,
    ]
    const trackerPhoneImagesSPA = [
        spa_t_1_main,
        spa_t_2_pickup_map,
        spa_t_3_sos,
    ]

    const getControllerAppImages = (index) => {
        let images
        if (currentLanguage.iso_code === 'ko') images = controllerPhoneImagesKOR
        else if (currentLanguage.iso_code === 'en') images = controllerPhoneImagesENG
        else if (currentLanguage.iso_code === 'es') images = controllerPhoneImagesSPA
        else images = controllerPhoneImagesENG

        return images[index]
    }

    const getTrackerAppImages = (index) => {
        let images
        if (currentLanguage.iso_code === 'ko') images = trackerPhoneImagesKOR
        else if (currentLanguage.iso_code === 'en') images = trackerPhoneImagesENG
        else if (currentLanguage.iso_code === 'es') images = trackerPhoneImagesSPA
        else images = trackerPhoneImagesENG

        return images[index]
    }

    // 현재 선택된 언어를 cookie를 통해 알아야 한다.
    // 맨 처음에 Cookie가 비어 있을 때를 대비해서, localStorage에 설정된 언어를 사용하고, 없으면 최종으로 en 으로 설정됨.
    const cookieLanguageIsoCode = cookies.get('i18next') || localStorage.getItem('lng') || 'en'
    const currentLanguage = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)

    return (
        <div className="gf__screenshots section__padding" id="screenshots">
            <div className="gf__screenshots-heading">
                <h1>{t("TOKEN Exploring the app screens")}</h1>
                <h3>{t("TOKEN Controller app screens")}</h3>
            </div>
            <div className="gf__screenshots-container">
                <Screen img={getControllerAppImages(0)} title={t("TOKEN Controller app")} text={t("TOKEN Main map screen 1")} />
                <Screen img={getControllerAppImages(1)} title={t("TOKEN Controller app")} text={t("TOKEN Main map screen 2")} />
                <Screen img={getControllerAppImages(2)} title={t("TOKEN Controller app")} text={t("TOKEN Navigation menu screen")} />
                <Screen img={getControllerAppImages(3)} title={t("TOKEN Controller app")} text={t("TOKEN Tracker list screen")} />
                <Screen img={getControllerAppImages(4)} title={t("TOKEN Controller app")} text={t("TOKEN Notification screen")} />
            </div>
            <div className="gf__screenshots-heading">
                <h3>{t("TOKEN Tracker app screens")}</h3>
            </div>
            <div className="gf__screenshots-container">
                <Screen img={getTrackerAppImages(0)} title={t("TOKEN Tracker app")} text={t("TOKEN Main menu screen")} />
                <Screen img={getTrackerAppImages(1)} title={t("TOKEN Tracker app")} text={t("TOKEN Pick-up map screen")} />
                <Screen img={getTrackerAppImages(2)} title={t("TOKEN Tracker app")} text={t("TOKEN SOS request screen")} />
            </div>
        </div>
    )
}

export default Screenshots