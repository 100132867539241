// React version 18을 위해 수정했음
import { createRoot } from 'react-dom/client'
import React, { Suspense } from 'react';

import App from './App'
import './index.css'

const loadingMarkup = (
    <div className="py-4 text-center">
        <h3>Loading...</h3>
    </div>
)

const container = document.getElementById('root'); // App 컴포넌트가 id가 root 인 div에 그려진다.
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Suspense fallback={loadingMarkup}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Suspense>
);
