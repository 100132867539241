import React, { useEffect, useState } from 'react'

import { Header, WhatGF, Features, Usage, Screenshots, Footer } from './containers'
import { Navbar, Download, Document, Popup } from './components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import './app.css'

export const storageType = localStorage;
export const langSelectedPropertyName = 'lang_selected'
const consentPropertyName = 'cookie_notice_accepted';

// true 이면, Cookie Notice 팝업 기능을 강제로 사용하지 않음.
const enforceCookieNoticePopupPass = true  // EC_HJM_001

// Cookie Notice 팝업을 뛰웠지만, 사용자가 Close시킨 후, 현재 Session에서는 Cookie Notice 팝업을 더 이상 뛰우지 않기 위한 Flag로 사용한다.
let userCancelledByCloseButton = false

const App = () => {
    const { t } = useTranslation();

    useEffect(() => {
        // 블라우저 Title Text를 새로운 언어로 표시한다.
        document.title = t("TOKEN app_tile")
    }) // deps 파라미터가 없으므로, 이 Component가 리렌더링 될 때마다 호출된다.

    const [openPopup, setOpenPopup] = useState(false)

    const shouldShowPopup = () => !storageType.getItem(consentPropertyName) && !userCancelledByCloseButton

    // 사용자가 아직 Cookie 사용에 대해 동의 하지 않았다면, Cookie Notice 팝업을 뛰운다.
    if (shouldShowPopup() && !enforceCookieNoticePopupPass) { // EC_HJM_001
        // Cookie Notice 팝업 0.5초 후에 뛰운다.
        setTimeout(() => {
            // Cookie Notice 팝업을 Open한다
            setOpenPopup(true)
        }, 500);
    }

    // Cookie Notice 팝업에서 Accept 버턴을 클릭하면 호출됨
    const onAcceptCookieNotice = () => {
        // Cookie Notice 팝업을 Close한다.
        setOpenPopup(false)

        // Cookie Notice 팝업을 사용자에게 보여 주었고 사용자가 동의했음 Flag를 Local Storage에 저장함.
        storageType.setItem(consentPropertyName, true)
    }

    // Cookie Notice 팝업에서 Close 버턴을 클릭하면 호출됨
    const onCloseCookieNotice = () => {
        // Cookie Notice 팝업을 Close한다.
        setOpenPopup(false)

        // 이번 Session에서는 Cookie Notice 팝업을 더이상 보이지 않게 한다. 다음 Session에서는 보이게 된다.
        userCancelledByCloseButton = true
    }

    return (
        <BrowserRouter>
            <div className="App">
                { /* Navigation Bar */}
                <div className="gradient__bg">
                    <Navbar />
                </div>

                <Routes>
                    { /* Home */}
                    <Route path='/'
                        element={
                            <>
                                <div className="gradient__bg">
                                    <Header />
                                </div>
                                <WhatGF />
                                <Features />
                                <Usage />
                                <Screenshots />
                                <Download />
                            </>
                        } />
                    { /* What GeoFamily */}
                    <Route path='/whatgf'
                        element={
                            <>
                                <WhatGF />
                                <Download />
                            </>
                        } />
                    { /* Features */}
                    <Route path='/features'
                        element={
                            <>
                                <Features />
                                <Download />
                            </>
                        } />
                    { /* Usage */}
                    <Route path='/usage'
                        element={
                            <>
                                <Usage />
                                <Download />
                            </>
                        } />
                    { /* Screenshots */}
                    <Route path='/screenshots'
                        element={
                            <>
                                <Screenshots />
                                <Download />
                            </>
                        } />
                    { /* Download 와 Blog는 외부 Direct Link이므로 빠짐 */}
                    { /* Terms and Conditions */}
                    <Route path='/terms' element={<Document docName='terms' />} />
                    { /* Privacy Policy */}
                    <Route path='/privacy' element={<Document docName='privacy' />} />
                </Routes>

                { /* Footer Area */}
                <Footer />
            </div>

            { /* Cookie Notice 팝업 */}
            <Popup isOpen={openPopup} onAccept={onAcceptCookieNotice} onClose={onCloseCookieNotice} />

        </BrowserRouter>
    )
}

export default App