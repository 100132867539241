import React from 'react'
import './feature.css'

const Feature = ({ title, text }) => {

    return (
        <div className="gf__feature-container__feature">
            <div className="gf__feature-container__feature-title">
                <div />
                <h3>{title}</h3>
            </div>
            <div className="gf__feature-container__feature-text">
                <>
                    {
                        text.split("\n").map((i, key) => {
                            return <p key={key}>{i}</p>;
                        })
                    }
                </>
            </div>
        </div>
    )
}

export default Feature