import React from 'react'
import { kor_google_play_badge, eng_google_play_badge, spa_google_play_badge } from '../../assets/images/all_images'
import { GEOFAMILY_APP_PACKAGE_URL } from '../../extra/constants'
import cookies from 'js-cookie'  // JS-Cookie 패키지
import { languages } from '../../components/navbar/Navbar';

import './download.css'

const Download = () => {

    // 현재 선택된 언어를 cookie를 통해 알아야 한다.
    // 맨 처음에 Cookie가 비어 있을 때를 대비해서, localStorage에 설정된 언어를 사용하고, 없으면 최종으로 en 으로 설정됨.
    const cookieLanguageIsoCode = cookies.get('i18next') || localStorage.getItem('lng') || 'en'
    const currentLanguage = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)
    const getGooglePlayBadgeImage = () => {
        if (currentLanguage.iso_code === 'ko') return kor_google_play_badge
        else if (currentLanguage.iso_code === 'en') return eng_google_play_badge
        else if (currentLanguage.iso_code === 'es') return spa_google_play_badge
        else return eng_google_play_badge
    }

    return (
        <div className="gf__download section__padding" id="download">
            <a href={GEOFAMILY_APP_PACKAGE_URL} target="_blank" rel="noreferrer">
                <img src={getGooglePlayBadgeImage()} alt="google play badge" />
            </a>
        </div>
    )
}

export default Download