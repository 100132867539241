import React from 'react'

import { RiCloseLine } from 'react-icons/ri'
import 'bootstrap/dist/js/bootstrap.js'        // Bootstrap JS
import 'bootstrap/dist/css/bootstrap.min.css'  // Bootstrap CSS
import { useTranslation, Trans } from "react-i18next";
import { LinkText } from '../../components'

import './popup.css'

const Popup = ({ isOpen, onClose, onAccept }) => {
    const { t } = useTranslation()

    if (!isOpen) return null // Not display

    const LINK = "/privacy" // 변경되어야 함 // <Link to="/terms" style={{ color: '#F49867' }}>{t("TOKEN Cookie Policy")}</Link>

    const linkTitle = t("TOKEN Cookie Policy")
    return (
        <div className='gf__popup scale-up-center'>
            <div className='gf__popup-close-button'>
                {/* X 아이콘 */}
                <RiCloseLine color="#000" size={28} onClick={onClose} />
            </div>
            <div className='gf__popup-container'>
                <div className='gf__popup-container__notice'>
                    <h4>{t("TOKEN cookie_notice_title")}</h4>
                    <Trans i18nKey="TOKEN cookie_notice_text"
                        components={{
                            cookie_policy_link_tag: <LinkText to={LINK} title={linkTitle} />
                        }}
                    />
                </div>
                <div className='gf__popup-container__buttons'>
                    <button type="button" class='btn btn-primary btn-xl btn-block' onClick={onAccept} >{t('TOKEN Accept')}</button>
                </div>
            </div>
        </div>
    )
}

export default Popup