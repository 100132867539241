import React, { useState, useEffect } from 'react'
import { eng_privacy_file, kor_privacy_file, eng_terms_file, kor_terms_file } from '../../assets/texts/all_texts'
import cookies from 'js-cookie'  // JS-Cookie 패키지
import { languages } from '../../components/navbar/Navbar';
import { useTranslation } from "react-i18next";

import './document.css'

const Document = ({ docName }) => {
    const { t } = useTranslation()

    // 현재 선택된 언어를 cookie를 통해 알아야 한다.
    // 맨 처음에 Cookie가 비어 있을 때를 대비해서, localStorage에 설정된 언어를 사용하고, 없으면 최종으로 en 으로 설정됨.
    const cookieLanguageIsoCode = cookies.get('i18next') || localStorage.getItem('lng') || 'en'
    const currentLanguage = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)
    const getPrivacyPolicyFile = () => {
        if (currentLanguage.iso_code === 'ko') return kor_privacy_file
        else if (currentLanguage.iso_code === 'en') return eng_privacy_file
        else return eng_privacy_file
    }
    const getTermsAndConditionsFile = () => {
        if (currentLanguage.iso_code === 'ko') return kor_terms_file
        else if (currentLanguage.iso_code === 'en') return eng_terms_file
        else return eng_terms_file
    }

    const [docTitle, setDocTitle] = useState("")
    const [docText, setDocText] = useState("")

    useEffect(() => {
        getTextFromPromise(docName);
    }, [docName, currentLanguage]); // dependency로 docName을 사용함으로써 'privacy' 와 'terms' 에 대해 바로 업데이트할 수 있다.

    const fetchFilePromise = (file) => {
        // run the fetch() and return the Promise
        return fetch(file)
            .then(r => r.text())
            .then(text => {
                //console.log('fetchFilePromise :     ', text)
                return text
            })
    }
    const getTextFromPromise = async (docName) => {
        let file
        if (docName === 'privacy') {
            setDocTitle(t("TOKEN Privacy Policy"))
            file = getPrivacyPolicyFile()
        }
        else if (docName === 'terms') {
            setDocTitle(t("TOKEN Terms And Conditions"))
            file = getTermsAndConditionsFile()
        }

        // run and get the Promise, and then get the value from the Promise by await
        const p = await fetchFilePromise(file);
        // set the value from the Promise
        setDocText(p)
    }

    return (
        <div className="gf__document section__padding" id={docName}>
            <div className="gf__document__title">
                <h3>{docTitle}</h3>
            </div>
            <div className="gf__document__text">
                <>
                    {
                        docText.split("\n").map((i, key) => {
                            return <p key={key}>{i}</p>;
                        })
                    }
                </>
            </div>
        </div>
    )
}

export default Document
