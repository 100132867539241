import React from 'react'
import './feature.css'

const FeatureArray = ({ title, textArray }) => {

    return (
        <div className="gf__feature-container__feature">
            <div className="gf__feature-container__feature-title">
                <div />
                <h3>{title}</h3>
            </div>
            <div className="gf__feature-container__feature-text">
                {   // Multiple text lines.
                    textArray.map((item) => (
                        <>
                            {
                                item.split("\n").map((i, key) => {
                                    return <p key={key}>{i}</p>;
                                })
                            }
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default FeatureArray