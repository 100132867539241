import React from 'react'
import { Feature } from '../../components'
import { useTranslation } from "react-i18next";

import './features.css'

const Features = () => {
    const { t } = useTranslation()

    const featuresList = [
        {
            title: t("TOKEN Real Time Locations"),
            text: t("TOKEN real_time_locations_description"),
        },
        {
            title: t("TOKEN Location History Analysis"),
            text: t("TOKEN location_history_analysis_description"),
        },
        {
            title: t("TOKEN Zone Alarm"),
            text: t("TOKEN zone_alarm_description"),
        },
        {
            title: t("TOKEN SOS Alert"),
            text: t("TOKEN sos_alert_description"),
        },
        {
            title: t("TOKEN Pick-Up Alert"),
            text: t("TOKEN pick-up_alert_description"),
        },
        {
            title: t("TOKEN Track-Me Report"),
            text: t("TOKEN track-me_report_desciption"),
        },
    ]

    return (
        <div className="gf__features section__padding" id="features">
            <div className="gf__features-heading">
                <h1 className="gradient__text">{t("TOKEN Features")}</h1>
            </div>
            <div className="gf__features-container">
                {   // Multiple Features.
                    featuresList.map((item, index) => (
                        <Feature title={item.title} text={item.text} key={item.title + index} />
                    ))
                }
            </div>
        </div>
    )
}

export default Features