import React, { useState, useEffect } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { geofamily_logo_with_text } from '../../assets/images/all_images'
import { GEOFAMILY_APP_PACKAGE_URL, GEOFAMILY_NAVER_BLOG_URL, GEOFAMILY_BLOGGER_BLOG_URL, GEOFAMILY_TUMBLR_BLOG_URL } from '../../extra/constants'
import { Link } from 'react-router-dom'

import 'bootstrap/dist/js/bootstrap.js'        // Bootstrap JS
import 'bootstrap/dist/css/bootstrap.min.css'  // Bootstrap CSS
import 'flag-icon-css/css/flag-icons.min.css'  // All country flags in SVG
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import cookies from 'js-cookie'  // JS-Cookie 패키지

import { storageType, langSelectedPropertyName } from './../../App'; // EC_HJM_001

import './navbar.css'

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // Browser의 사용자 언어 정보를 읽어 오기 위해...
    .use(HttpApi)
    .init({
        supportedLngs: ['ko', 'en', 'es'], // 지원되는 언어들을 선언. // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes

        fallbackLng: 'en',  // translation.json에서 사용자 언어가 발견되지 않을 때는 영어로..

        // lng: 'en',  // 이 라인은 사용하지 말것. 이유: detection을 Override하고, 다시 Web 페이지를 시작 할 때 무조건 이 언어로 설정됨.

        detection: { // i18next-browser-languagedetector  https://github.com/i18next/i18next-browser-languageDetector#detector-options
            // 사용자 언어 검출을 위한 Priority 순서 설정. was ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
            order: ['path', 'cookie', 'localStorage', 'htmlTag', 'subdomain'],
            // 사용자 언어가 Cashe되는 장소. was ['localStorage', 'cookie']
            caches: ['cookie'],
        },

        backend: { // i18next-http-backend  https://github.com/i18next/i18next-http-backend#backend-options
            // 다국어 언어 번역 데이터 파일들이 있는 path를 지정. lng는 현재 사용언어.
            loadPath: '/assets/locales/{{lng}}/translation.json',

        },
    });

export const languages = [
    {
        iso_code: 'ko',        // 언어를 선택하기 위한 ISO Code :  https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
        name: '한국어',
        dir: 'ltr',
        country_code: 'kr',    // 국기 표시를 위한 Country Code :  https://mdbootstrap.com/docs/standard/content-styles/flags/
    },
    {
        iso_code: 'en',
        name: 'English',
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        iso_code: 'es',
        name: 'Español',
        dir: 'ltr',
        country_code: 'es',
    },
]

const DEFAULT_LANGUAGE_ISO_CODE = 'en'

// 이 변수는 Local Storage에서 'lang_selected'가 설정되지 않은 경우 에서만 이용됨.
let mounted = false

const Navbar = () => {
    const { t } = useTranslation();

    const [toggleMenu, setToggleMenu] = useState(false);

    /*
     * 유의 : 사용자가 사용하는 최초의 경우, i18next는 무조건 cookies.get('i18next') 가 "en"을 리턴하고 있다.
     * 그러므로 최초 사용시는 Browser의 설정 언어로 표시되는 것이 이치에 맞다.
     */
    const getCurrentLanguage = () => {
        let language = null
        if (!storageType.getItem(langSelectedPropertyName)) {
            /*
             * 사용자가 사용하는 최초의 경우.. 그리고 사용자가 언어 선택 DropDown 메뉴에서 언어 설정을 한 적이 없는 경우..
             * Local Storage에서 'lang_selected'가 설정되지 않았으므로, 이 때는 Browser의 설정 언어로 표시되어야 한다.
             * 
             * 그런데 그 Browser의 설정 언어가, 우리가 지원하는 언어들 리스트에 속해 있으면 그 언어를 사용하고, (navigator.language가 ISO Code임)
             * 그 리스트에 없으면 영어를 사용해야 하는 것이 이치에 맞다.
             */
            const defaultLanguageIsoCode =
                languages.find((lang) => lang.iso_code === navigator.language) ? navigator.language : DEFAULT_LANGUAGE_ISO_CODE
            language = languages.find((lang) => lang.iso_code === defaultLanguageIsoCode)

            console.log("getCurrentLanguage() due to Default ..... ", language.iso_code)

            if (!mounted) { // 이 루틴은 useEffect()로 보내면 에러 발생하므로 여기에 둘 것.
                /* 
                 * Browser가 처음 시작될 때, 위에서 결정된 Default 언어로 'i18next' Cookie에 저장한다.
                 * (이유: 'i18next' Cookie에 현재 언어가 저장되어 있지 않으면, i18next는 무조건 영어로 랜더링하기 때문이다.)
                 * 이 과정은 사용자 Session에서 한번만 구행되면 된다.
                 */
                i18next.changeLanguage(language.iso_code)

                mounted = true
            }
        }
        else {
            /*
             * 그 외의 경우에는...
             * 이전에 사용자에 의해 선택된 언어를 i18next의 cookie를 통해 가져와야 한다. 
             * (만일의 경우를 대비해서 그 Cookie가 비어 있을 때를 대비해서, 없으면 Default 언어로 설정되야 함.) 
             */
            const cookieLanguageIsoCode = cookies.get('i18next') || DEFAULT_LANGUAGE_ISO_CODE
            language = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)

            console.log("getCurrentLanguage() due to Selection ..... ", language.iso_code)
        }

        return language
    }

    const currentLanguage = getCurrentLanguage()

    useEffect(() => {
        // 현재 선택된 언어에 따라서 좌우, 우좌 방향으로 문장을 표시한다. (아랍어는 우좌 방향임)
        document.body.dir = currentLanguage.dir

    }, [currentLanguage]) // 이 Component가 처음 마운트 될 때에도 호출이 되고, currentLanguage가 바뀔 때에도 호출된다.

    const getBlogLink = () => {
        if (currentLanguage.iso_code === 'ko') return GEOFAMILY_NAVER_BLOG_URL
        else if (currentLanguage.iso_code === 'en') return GEOFAMILY_BLOGGER_BLOG_URL
        else if (currentLanguage.iso_code === 'es') return GEOFAMILY_TUMBLR_BLOG_URL
        else return GEOFAMILY_BLOGGER_BLOG_URL
    }

    const Menu = () => (
        <>
            <p><Link to="/" onClick={handleLinkClick}>{t("TOKEN Home")}</Link></p>
            <p><Link to="/whatgf" onClick={handleLinkClick}>{t("TOKEN What is GeoFamily?")}</Link></p>
            <p><Link to="/features" onClick={handleLinkClick}>{t("TOKEN Features")}</Link></p>
            <p><Link to="/usage" onClick={handleLinkClick}>{t("TOKEN Usage Steps")}</Link></p>
            <p><Link to="/screenshots" onClick={handleLinkClick}>{t("TOKEN Screenshots")}</Link></p>
            <p><a href={GEOFAMILY_APP_PACKAGE_URL} target="_blank" rel="noreferrer" onClick={handleLinkClick}>{t("TOKEN Download")}</a></p>
            <p><a href={getBlogLink()} target="_blank" rel="noreferrer" onClick={handleLinkClick}>{t("TOKEN Blog")}</a></p>
        </>
    )

    const handleLinkClick = (event) => {
        /* 
         * toggleMenu가 true 일 때, 즉 Drop Down 메뉴판이 펼쳐져 있을 때, 
         * 어떤 메뉴 항목을 터치하여 Link가 수행될 때 false로 설정되게 만든다. 즉, Drop Down 메뉴판을 닫는다.
         */
        toggleMenu && setToggleMenu(false)
    };

    return (
        <div className='gf__navbar'>
            <div className='gf__navbar-links'>
                { /* GeoFamily Logo */}
                <div className='gf__navbar-links_logo'>
                    <img src={geofamily_logo_with_text} alt='GeoFamily Logo' />
                </div>
                { /* 가로 방향의 메뉴들 */}
                <div className="gf__navbar-links_container">
                    <Menu />
                </div>
            </div>

            { /* Language Selection using Bootstrap */}
            <div className="gpt3__navbar-language language-select-root">
                <div className="dropdown">
                    <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <span className={`flag-icon flag-icon-${currentLanguage.country_code} mx-2`} /* 선택된 언어의 국기 표시 */ >
                        </span>
                        {currentLanguage.name /* 선택된 언어의 언어이름 표시 */}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {languages
                            // 현재 설정된 언어는 언어 후보 리스트에서 제거함.
                            .filter(({ iso_code, name, country_code }) => currentLanguage.iso_code !== iso_code)
                            // 각 언어 후보의 국기와 언어이름을 Dropdown 리스트로 보여줌.
                            .map(({ iso_code, name, country_code }) => (
                                <li key={country_code}>
                                    <button className='dropdown-item'
                                        onClick={() => { // 사용자가 Drop Down 메뉴판에서 새로운 언어를 선택할 때 호출됨.
                                            if (!storageType.getItem(langSelectedPropertyName)) {
                                                // 사용자가 사용할 언어를 선택했으므로 이제 그 Local Storage에서 'lang_selected'를 ture로 설정한다.
                                                storageType.setItem(langSelectedPropertyName, true)
                                            }

                                            // 새로 설정한 언어로 다시 렌더링하고, 'i18next' Cookie에 그 언어를 저장함.
                                            i18next.changeLanguage(iso_code)

                                            console.log("Selected Language : ", iso_code)
                                        }
                                        }>
                                        <span className={`flag-icon flag-icon-${country_code} mx-2`} /* 후보 언어의 국기 표시 */ >
                                        </span>
                                        {name /* 후보 언어의 언어이름 표시 */}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            <div className="gf__navbar-menu">
                { /* 햄버거 아이콘 또는 X 아이콘 */}
                {
                    // X 아이콘
                    toggleMenu ? <RiCloseLine className="gf__navbar-menu_icon" color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                        // 햄버거 3 라인 아이콘
                        : <RiMenu3Line className="gf__navbar-menu_icon" color="#fff" size={27} onClick={() => setToggleMenu(true)} />
                }
                { /* 세로 방향의 메뉴들 */}
                {toggleMenu && ( // Mobile 용 Navbar
                    <div className="gf__navbar-menu_container scale-up-center">
                        <div className="gf__navbar-menu_container-links">
                            <Menu />
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default Navbar