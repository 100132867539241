import React from 'react'
import './screen.css'

const Screen = ({ img, title, text }) => {
    return (
        <div className="gf__screen">
            <div className="gf__screen__image">
                <img src={img} alt={text} loading="lazy" />
            </div>
            <div className="gf__screen__details">
                <div>
                    <p>{title}</p>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default Screen