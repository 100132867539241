import React from 'react'
import { kor_main_phones_image, eng_main_phones_image, spa_main_phones_image } from '../../assets/images/all_images'
import { kor_google_play_badge, eng_google_play_badge, spa_google_play_badge } from '../../assets/images/all_images'
import { GEOFAMILY_APP_PACKAGE_URL } from '../../extra/constants'
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'  // JS-Cookie 패키지
import { languages } from '../../components/navbar/Navbar';

import './header.css'

const Header = () => {
    const { t } = useTranslation();

    // 현재 선택된 언어를 cookie를 통해 알아야 한다.
    // 맨 처음에 Cookie가 비어 있을 때를 대비해서, localStorage에 설정된 언어를 사용하고, 없으면 최종으로 en 으로 설정됨.
    const cookieLanguageIsoCode = cookies.get('i18next') || localStorage.getItem('lng') || 'en'
    const currentLanguage = languages.find((lang) => lang.iso_code === cookieLanguageIsoCode)

    const getMainPhonesImage = () => {
        if (currentLanguage.iso_code === 'ko') return kor_main_phones_image
        else if (currentLanguage.iso_code === 'en') return eng_main_phones_image
        else if (currentLanguage.iso_code === 'es') return spa_main_phones_image
        else return eng_main_phones_image
    }

    const getGooglePlayBadgeImage = () => {
        if (currentLanguage.iso_code === 'ko') return kor_google_play_badge
        else if (currentLanguage.iso_code === 'en') return eng_google_play_badge
        else if (currentLanguage.iso_code === 'es') return spa_google_play_badge
        else return eng_google_play_badge
    }

    return (
        <div className="gf__header section__padding" id="home">
            <div className="gf__header-content">
                <h1 className="gradient__text">{t("TOKEN header_main_title")}</h1>
                <p>{t("TOKEN header_sub_title")}</p>

                <div className="gf__header-content__badge">
                    <a href={GEOFAMILY_APP_PACKAGE_URL} target="_blank" rel="noreferrer">
                        <img src={getGooglePlayBadgeImage()} alt='google play badge' />
                    </a>
                </div>
            </div>

            <div className="gf__header-image">
                <img src={getMainPhonesImage()} alt={t("TOKEN header_main_title")} />
            </div>
        </div>
    )
}

export default Header