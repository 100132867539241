import React from 'react'

import './popup.css'

const LinkText = ({ to, title }) => {
    return (
        <a className='gf__link-text' href={to || '#'} target="_blank" rel="noreferrer" title={title || ''}>
            {title}
        </a>
    )
}

export default LinkText