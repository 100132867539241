import React from 'react'
import { geofamily_logo_with_text, naver_logo, blogger_logo, tumblr_logo } from '../../assets/images/all_images'
import { GEOFAMILY_NAVER_BLOG_URL, GEOFAMILY_BLOGGER_BLOG_URL, GEOFAMILY_TUMBLR_BLOG_URL } from '../../extra/constants'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import './footer.css'

const Footer = () => {
    const { t } = useTranslation()

    return (
        <div className="gf__footer section__padding">
            <div className="gf__footer-links">
                <div className='gf__footer-links__logo'>
                    <img src={geofamily_logo_with_text} alt='GeoFamily Logo' />
                    <p>{t("TOKEN contact_email")}</p>
                </div>
                <div className='gf__footer-links__items'>
                    <p><Link to="/terms">{t("TOKEN Terms And Conditions")}</Link></p>
                    <p><Link to="/privacy">{t("TOKEN Privacy Policy")}</Link></p>
                </div>
                <div className='gf__footer-links__sns'>
                    <a href={GEOFAMILY_NAVER_BLOG_URL} target="_blank" rel="noreferrer">
                        <img src={naver_logo} alt='Naver Blog' />
                    </a>
                    <a href={GEOFAMILY_BLOGGER_BLOG_URL} target="_blank" rel="noreferrer">
                        <img src={blogger_logo} alt='Blogger Blog' />
                    </a>
                    <a href={GEOFAMILY_TUMBLR_BLOG_URL} target="_blank" rel="noreferrer">
                        <img src={tumblr_logo} alt='Tumblr Blog' />
                    </a>
                </div>
            </div>
            <div className="gf__footer-copyright">
                <p>© {new Date().getFullYear()} GeoQuest Inc. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer